import React from 'react';
import { useClasses } from './hooks/useClasses';
import { TableBodyProps } from './TableBodyTypes';

import './TableBody.scss';

export const TableBodyView: React.FC<TableBodyProps> = (props) => {
    const { children, className } = props;
    const classes = useClasses({ className });

    return <tbody className={classes.root}>{children}</tbody>;
};
