import classNames from 'classnames';
import { SelectOptionsProps } from '../SelectOptionsTypes';

type Params = Pick<SelectOptionsProps, 'className' | 'size'> & {
    isClearAllButtonVisible?: boolean;
};

const rootClassName = 'ui-select-options';

export const useClasses = (params: Params) => {
    const { className, size, isClearAllButtonVisible } = params;
    return {
        root: classNames(rootClassName, className),
        dropdownMenu: {
            scrollbar: classNames(`${rootClassName}__scrollbar`, { [`${rootClassName}__scrollbar--with-clear-all-button`]: isClearAllButtonVisible }),
            withClearAllButton: `${rootClassName}__scrollbar--with-clear-all-button`,
            actions: `${rootClassName}__actions`,
            items: `${rootClassName}__items`,
            clearAllButton: `${rootClassName}__clear-all-button`,

            divider: {
                root: classNames(`${rootClassName}__divider`, `${rootClassName}__divider--size-${size}`),
                header: {
                    root: `${rootClassName}__divider__header`,
                    label: `${rootClassName}__divider__header__label`,
                    count: `${rootClassName}__divider__header__count`,
                },
            },
        },
    };
};
