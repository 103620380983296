import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash-es';

interface IntersectionObserverContextType {
    observe: (element: Element, callback: (isIntersecting: boolean) => void) => void;
    unobserve: (element: Element) => void;
    getObserver: () => IntersectionObserver | void;
    onIntersect?: VoidFunction;
    isReady: boolean;
}

type IntersectionObserverProps = {
    rootRef: Element;
    options?: IntersectionObserverInit;
    // default observer action, it's possible to call custom callback from observable component
    onIntersect?: VoidFunction;
};

const IntersectionObserverContext = React.createContext<IntersectionObserverContextType>({ observe: _.noop, unobserve: _.noop, getObserver: _.noop, isReady: false, onIntersect: _.noop });

export const IntersectionObserverContextView: React.FC<React.PropsWithChildren<IntersectionObserverProps>> = (props) => {
    const { children, options = {}, onIntersect, rootRef } = props;
    const observerRef = useRef<IntersectionObserver>(null);
    const elementCallbackMap = useRef<Map<Element, (isIntersecting: boolean) => void>>(new Map());
    const [isReady, setIsReady] = useState<boolean>(false);

    const observe: IntersectionObserverContextType['observe'] = (element, callback) => {
        if (!observerRef.current || !element) {
            return;
        }
        elementCallbackMap.current.set(element, callback);
        observerRef.current.observe(element);
    };

    const unobserve: IntersectionObserverContextType['unobserve'] = (element) => {
        if (!observerRef.current || !element) {
            return;
        }
        elementCallbackMap.current.delete(element);
        observerRef.current.unobserve(element);
    };

    const getObserver: IntersectionObserverContextType['getObserver'] = () => {
        return observerRef.current;
    };

    const createObserver = useCallback(() => {
        return new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const callback = elementCallbackMap.current.get(entry.target);
                    if (callback) {
                        callback(entry.isIntersecting);
                    }
                });
            },
            { root: rootRef, ...options },
        );
    }, [rootRef, elementCallbackMap.current]);

    useEffect(() => {
        if (rootRef) {
            observerRef.current = createObserver();
            setIsReady(true);
        }
        return () => {
            if (rootRef) {
                observerRef.current?.disconnect();
            }
        };
    }, [createObserver]);

    return <IntersectionObserverContext.Provider value={{ observe, unobserve, getObserver, isReady, onIntersect }}>{children}</IntersectionObserverContext.Provider>;
};

export const useIntersectionObserver = () => useContext(IntersectionObserverContext);
