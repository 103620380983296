import withSuspense from '../../../../common/hocs/withSuspense';
import { connect } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import TransactionRows from './TransactionRows';
import { getTransactionRowsList } from './TransactionRowsActions';
import { updateCustomCostObjectiveArray } from '../../../../common/user/UserActions';
import { selectTransactionRowsListLoadable } from './TransactionRowsReducers';
import { getCompanyCustomCostObjectives, getCurrentUser } from '../../../../common/user/UserSelectors';
import { DispatchProps, Props } from './TransactionRowsTypes';

const mapStateToProps = (state: GlobalState): Pick<Props, 'transactionRowsLoadable' | 'customCostObjectives' | 'userData'> => ({
    transactionRowsLoadable: selectTransactionRowsListLoadable(state),
    customCostObjectives: getCompanyCustomCostObjectives(state),
    userData: getCurrentUser(state),
});

const mapDispatchToProps: DispatchProps = {
    getTransactionRowsList,
    updateCustomCostObjectiveArray,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSuspense(TransactionRows));
