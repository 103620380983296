import { BaseSearch, PagedListContainer, TransactionRowDTO } from '../../../../services/types/ApiTypes';
import { createRequest } from './TransactionRowsReducers';
import { DispatchThunk } from '../../../../storeConfig';
import { GlobalState } from '../../../../rootReducer';
import { loadableDataActionsWithRequest } from '../../../../common/utils/LoadableData';
import { notify } from '../../../../common/utils/notify';
import api from '../../../../services/ApiServices';
import i18nInstance from '../../../../i18n';

const ns = 'invoice/invoice-transaction-rows/';

export const getInvoiceTransactionRowsLoadable = loadableDataActionsWithRequest<BaseSearch, PagedListContainer<TransactionRowDTO>>(`${ns}ALL_TRANSACTION_ROWS`);

export const getTransactionRowsList = (id: number, page?: number) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const searchParams = createRequest();
        searchParams.PagingOptions.Page = page || 1;
        try {
            dispatch(getInvoiceTransactionRowsLoadable.request(searchParams));
            const response = await api.invoice.getInvoiceTransactionRowsByInvoiceId(id, searchParams);
            if (response?.status === 200) {
                if (page !== 1) {
                    response.data.Items = [...getState().invoiceTransactionRows.transactionRowsLoadable.payload.Items, ...response.data.Items];
                }
                dispatch(
                    getInvoiceTransactionRowsLoadable.success({
                        request: searchParams,
                        result: response.data,
                    }),
                );
            } else {
                notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
            }
        } catch (e) {
            dispatch(
                getInvoiceTransactionRowsLoadable.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};
