import React from 'react';

import { InputAdornmentProps } from './InputAdornmentTypes';
import { useClasses } from './hooks/useClasses';

import './InputAdornmentView.scss';

export const InputAdornmentView: React.FC<React.PropsWithChildren<InputAdornmentProps>> = (props) => {
    const { children, vertical, className, ...rootProps } = props;
    const classes = useClasses({ className, vertical });
    return (
        <div className={classes.root} {...rootProps}>
            {children}
        </div>
    );
};
