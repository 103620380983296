import classNames from 'classnames';
import { InputProps } from '../InputViewTypes';

const rootClassName = 'ui-input';

type Params = Pick<InputProps, 'mode' | 'className' | 'inputSize' | 'error' | 'value' | 'defaultValue' | 'disabled' | 'viewModeValue'>;

export const useClasses = (params: Params) => {
    const { mode, className, inputSize, error, value, defaultValue, disabled, viewModeValue } = params;

    const isPlaceholderActive = Boolean((viewModeValue || value || defaultValue)?.toString()?.length);

    return {
        root: classNames(rootClassName, className, `${rootClassName}--size-${inputSize}`, { [`${rootClassName}--error`]: error, [`${rootClassName}--disabled`]: disabled }),
        input: {
            root: classNames(`${rootClassName}__input`, { [`${rootClassName}__input--hidden`]: mode !== 'edit' }),
        },
        viewMode: {
            root: classNames(`${rootClassName}__view-mode`, { [`${rootClassName}__view-mode--hidden`]: mode !== 'view' }),
            content: classNames(`${rootClassName}__view-mode__content`, { [`${rootClassName}__view-mode__content--placeholder`]: !isPlaceholderActive }),
        },
    };
};
