import React from 'react';
import { TableResizeTriggerProps } from './TableResizeTriggerTypes';
import { useClasses } from './hooks/useClasses';
import './TableResizeTrigger.scss';

export const TableResizeTriggerView: React.FC<TableResizeTriggerProps> = (props) => {
    const { className, height = 0, ...rootProps } = props;
    const classes = useClasses({ className });

    const triggerHeight = typeof height === 'string' ? height : `${height}px`;

    return (
        <div className={classes.root} {...rootProps} style={{ ...rootProps.style, height: triggerHeight }}>
            <div className={classes.inner} />
        </div>
    );
};
