import React, { useMemo } from 'react';
import { useDataGridContext } from '../../../hooks/useDataGridContext';
import { TablePreloader, TablePreloaderRowProps } from '../../../layout/TablePreloaderRow';

export type DataGridPreloaderTypes = Pick<TablePreloaderRowProps, 'text'>;

export const DataGridPreloaderView = React.forwardRef<HTMLTableRowElement, DataGridPreloaderTypes>(function DataGridPreloaderView(props, ref) {
    const { text } = props;
    const { columns, scrollableRef } = useDataGridContext();
    const scrollValues = useMemo(() => {
        return scrollableRef?.current?.getScrollState(true);
    }, [scrollableRef.current]);

    const colSpan = columns?.length;
    const minWidth = `${scrollValues?.clientWidth}px`;
    const left = `${scrollValues?.scrollLeft}px`;

    return <TablePreloader colSpan={colSpan} left={left} minWidth={minWidth} ref={ref} text={text} />;
});
