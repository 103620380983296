import classNames from 'classnames';
import { TableCellProps } from '../TableCellTypes';

const rootClassName = 'ui-table-cell';

type Params = Pick<TableCellProps, 'className' | 'hideOverflow' | 'actionsColumn' | 'justify'>;

export const useClasses = (props: Params) => {
    const { className, actionsColumn, hideOverflow, justify } = props;
    return {
        root: classNames(rootClassName, { [`${rootClassName}--actions-column`]: actionsColumn, [`${rootClassName}--hide-overflow`]: hideOverflow }, `${rootClassName}--justify-${justify}`, className),
        content: {
            root: classNames(`${rootClassName}__content`),
        },
    };
};
