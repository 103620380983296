import React from 'react';
import { useDataGridContext } from '../../../hooks/useDataGridContext';
import { Table, TableBody } from '../../../layout';
import { DataGridHead } from '../DataGridHead';
import { DataGridLayoutProps } from './DataGridLayoutTypes';

export const DataGridLayoutView = React.forwardRef<HTMLDivElement, DataGridLayoutProps>(function DataGridLayout(props, ref) {
    const { children, ...rest } = props;
    const { top } = useDataGridContext();
    return (
        <Table tableProps={{ style: { top, position: 'absolute', width: '100%' } }} rootRef={ref} rootProps={rest}>
            <DataGridHead />
            <TableBody>{children}</TableBody>
        </Table>
    );
});
