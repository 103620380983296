import classNames from 'classnames';
import { SelectOptionProps } from '../SelectOptionTypes';

const rootClassName = 'ui-select-option';

type Params = Pick<SelectOptionProps, 'selected' | 'size'>;

export const useClasses = (params: Params) => {
    const { selected, size } = params;
    return {
        root: classNames(rootClassName, `${rootClassName}--size-${size}`, { [`${rootClassName}--selected`]: selected }),
        icon: `${rootClassName}__icon`,
        content: `${rootClassName}__content`,
    };
};
