import { createDataId } from '../../../../../../../common/utils/dataId';

export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }
    return {
        root: dataId,
        actions: createDataId(dataId, 'actions'),
        count: createDataId(dataId, 'count'),
        optionsHeader: createDataId(dataId, 'options-header'),
        dividerRoot: createDataId(dataId, 'divider'),
        dropdownItems: createDataId(dataId, 'dropdown-items'),
    };
};
