import classNames from 'classnames';
import { DataTableTHeadProps } from '../TableHeadTypes';

const rootClassName = 'ui-table-head';

type Params = Pick<DataTableTHeadProps, 'className'>;

export const useClasses = (params: Params) => {
    const { className } = params;
    return { root: classNames(rootClassName, className) };
};
