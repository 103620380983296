import classNames from 'classnames';

import { SelectProps } from '../SelectTypes';

type Params = Pick<SelectProps, 'className' | 'error' | 'errorPlacement' | 'helperText' | 'inputSize'>;

const rootClassName = 'ui-select';

export const useClasses = (params: Params) => {
    const { className, error, errorPlacement, helperText, inputSize } = params;
    const isVerticalError = error && errorPlacement === 'vertical';

    return {
        root: classNames(rootClassName, `${rootClassName}--size-${inputSize}`, className),
        triggerButton: `${rootClassName}__select-trigger-container`,
        menuWrapper: classNames(`${rootClassName}__menu-wrapper`, { [`${rootClassName}__menu-wrapper__error--vertical`]: isVerticalError || Boolean(helperText) }),
        endContent: `${rootClassName}__end-content`,
    };
};
