import { Action } from 'redux-actions';
import storage from 'redux-persist/lib/storage';
import { ReducerFactory } from 'redux-actions-ts-reducer';
import { persistReducer, PersistConfig } from 'redux-persist';
import { LOCATION_CHANGE, RouterState } from 'connected-react-router';

import { GlobalState } from '../../../../rootReducer';
import { LoadableData } from '../../../../common/utils/LoadableData';
import { getInvoiceTransactionRowsLoadable } from './TransactionRowsActions';
import { BaseSearch, TransactionRowDTO, PagedListContainer } from '../../../../services/types/ApiTypes';

export const createRequest = (page = 1, count = 15): BaseSearch => ({
    PagingOptions: {
        Count: count,
        Page: page,
    },
    Restrictions: [],
    SortItems: [],
});

class State {
    transactionRowsLoadable = new LoadableData<PagedListContainer<TransactionRowDTO>, BaseSearch>();
    searchParams: BaseSearch = {
        ...createRequest(),
    };
}

const reducer = new ReducerFactory(new State())
    .addReducer(
        getInvoiceTransactionRowsLoadable.request,
        (state, action): State => {
            const transactionRowsLoadable = state.transactionRowsLoadable.withLoading(action.payload);
            return {
                ...state,
                searchParams: action.payload,
                transactionRowsLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceTransactionRowsLoadable.success,
        (state, action): State => {
            const transactionRowsLoadable = state.transactionRowsLoadable.withPayloadIfRequestEquals(action.payload);
            return {
                ...state,
                searchParams: action.payload.request,
                transactionRowsLoadable,
            };
        },
    )
    .addReducer(
        getInvoiceTransactionRowsLoadable.error,
        (state, action): State => {
            return {
                ...state,
                searchParams: action.payload.request,
                transactionRowsLoadable: state.transactionRowsLoadable.withErrorIfRequestEquals(action.payload),
            };
        },
    )
    .addReducer(
        LOCATION_CHANGE,
        (state, action: Action<RouterState>): State => {
            if (/\/invoiceconfirmation/.test(action.payload.location.pathname)) {
                return {
                    ...state,
                };
            }
            return {
                ...new State(),
            };
        },
    )
    .toReducer();

const persistConfig: PersistConfig<State> = {
    storage,
    key: 'invoice/invoice-transaction-rows',
    whitelist: ['searchParams'],
};

export default persistReducer(persistConfig, reducer);

export { State as InvoiceTransactionRowsViewState };

export const selectTransactionRowsListLoadable = (state: GlobalState) => state.invoiceTransactionRows.transactionRowsLoadable;
export const selectTransactionRowsListSearchParams = (state: GlobalState) => state.invoiceTransactionRows.searchParams;
