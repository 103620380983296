import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useClasses } from './hooks/useClasses';

import { Input, InputProps } from '../common/Input';
import Icon, { ICONS } from '../../../Icon/Icon';
import Tooltip from '../../../Tooltip/Tooltip';
import { InputAdornment } from '../common/InputAdornment';
import { InputBase } from '../common/InputBase';

import { TextInputInputState, TextInputProps } from './TextInputTypes';
import { useDataId } from './hooks/useDataId';
import './TextInputView.scss';

const initialInputState: TextInputInputState = { isFocused: false, mode: 'view' };

export const TextInputView = forwardRef((props: TextInputProps, ref: React.Ref<HTMLInputElement>) => {
    const {
        value,
        onChange,
        error,
        helperText,
        label,
        endContent,
        errorPlacement = 'vertical',
        placeholder,
        startContent,
        prefix,
        className,
        mode,
        inputSize = 'md',
        disabled,
        inputProps = {},
        inputBaseProps = {},
        dataId,
        infoTooltip,
        required,
        caretPositionInEditMode = 'end',
        onFocus,
        onBlur,
    } = props;
    const [inputState, setInputState] = useState<TextInputInputState>(initialInputState);

    const classes = useClasses({ className, inputSize });
    const dataIds = useDataId(dataId);
    const inputRef = useRef<HTMLInputElement>(null);

    const isCompactError = error && errorPlacement === 'compact';
    const isVerticalError = error && errorPlacement === 'vertical';
    const isFocused = props.focused || inputState.isFocused || inputState.mode === 'edit';
    const helperTextContent = isVerticalError ? error : helperText;
    const isInputError = Boolean(isVerticalError || isCompactError);
    const currentInputMode = mode || inputState.mode;

    const handleClickInputViewMode: InputProps['onClickViewMode'] = () => {
        if (mode === 'view' || disabled) {
            return;
        }
        setInputState((prev) => ({ ...prev, mode: 'edit' }));
    };

    const handleFocusInput: InputProps['onFocus'] = (e) => {
        setInputState((prev) => ({ ...prev, isFocused: true }));
        onFocus?.(e);
    };

    const handleBlurInput: InputProps['onBlur'] = (e) => {
        setInputState(initialInputState);
        onBlur?.(e);
    };

    const handleChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        onChange?.(e);
    };

    const updateCaretPosition = useCallback(() => {
        if (!inputRef.current) {
            return;
        }
        const input = inputRef.current;
        const pos = {
            start: input.value.length,
            end: input.value.length,
        };

        if (caretPositionInEditMode === 'start') {
            pos.start = 0;
            pos.end = 0;
        }

        if (caretPositionInEditMode === 'select-all') {
            pos.start = 0;
            pos.end = input.value.length;
        }
        inputRef.current.setSelectionRange(pos.start, pos.end);
    }, [mode, inputRef.current, inputState.mode]);

    useImperativeHandle(ref, () => {
        return inputRef.current;
    });

    useEffect(() => {
        if ((inputState.mode === 'edit' || mode === 'edit') && inputRef.current) {
            const input = inputRef.current;
            input.focus();
            updateCaretPosition();
        }
    }, [inputRef.current, inputState.mode, mode, updateCaretPosition]);
    return (
        <InputBase
            label={label}
            error={isInputError}
            helperText={helperTextContent}
            className={classes.root}
            prefix={prefix}
            focused={isFocused}
            helperTextProps={{ error: isVerticalError }}
            disabled={disabled}
            dataId={dataIds?.root}
            infoTooltip={infoTooltip}
            required={required}
            inputSize={inputSize}
            {...inputBaseProps}
        >
            <Input
                value={value}
                mode={currentInputMode}
                onClickViewMode={handleClickInputViewMode}
                onBlur={handleBlurInput}
                onChange={handleChangeTextInput}
                ref={inputRef}
                error={error}
                placeholder={currentInputMode === 'view' && placeholder}
                inputSize={inputSize}
                disabled={disabled}
                onFocus={handleFocusInput}
                endAdornment={
                    <InputAdornment>
                        {isCompactError && (
                            <Tooltip content={error}>
                                <span className={classes.error.content}>
                                    <Icon name={ICONS.ALERT_FILLED} />
                                </span>
                            </Tooltip>
                        )}
                        {endContent && endContent}
                    </InputAdornment>
                }
                startAdornment={startContent && <InputAdornment>{startContent}</InputAdornment>}
                dataId={dataIds?.input}
                {...inputProps}
            />
        </InputBase>
    );
});
