import React, { useCallback } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { FixedSizeListProps } from 'react-window';

import { useClasses } from './hooks/useClasses';

import './DataGridCustomScrollbar.scss';

export const DataGridCustomScrollbarView: FixedSizeListProps['outerElementType'] = React.forwardRef(function DataGridCustomScrollbar(props, ref: React.MutableRefObject<Scrollbar & HTMLDivElement>) {
    const { className, children, onScroll, style } = props;
    const classes = useClasses({ className });

    const handleScroll = useCallback(
        (currentTarget: any) => {
            onScroll({ currentTarget });
        },
        [onScroll],
    );

    return (
        <Scrollbar className={classes.root} style={{ ...style, overflow: 'hidden' }} onScroll={handleScroll} ref={ref}>
            {children}
        </Scrollbar>
    );
});
