import classNames from 'classnames';
import { TableProps } from '../TableTypes';

const rootClassName = 'ui-table';

type Params = Pick<TableProps, 'className'>;

export const useClasses = (params: Params) => {
    const { className } = params;
    return {
        root: classNames(rootClassName, className),
        scrollbars: `${rootClassName}__scrollbars`,
        table: {
            root: `${rootClassName}__table`,
        },
    };
};
