import classNames from 'classnames';
import { InputAdornmentProps } from '../InputAdornmentTypes';

type Params = Pick<InputAdornmentProps, 'className' | 'vertical'>;

const rootClassName = 'ui-input-adornment';

export const useClasses = (params: Params) => {
    const { className, vertical } = params;
    return {
        root: classNames(rootClassName, className, { [`${rootClassName}--vertical`]: vertical }),
    };
};
