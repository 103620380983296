import { InputLabelProps } from '../InputLabelTypes';
import classNames from 'classnames';

export type Params = Pick<InputLabelProps, 'className' | 'disabled'>;

const rootClassName = 'ui-input-label';

export const useClasses = (params: Params) => {
    const { className, disabled } = params;
    return {
        root: classNames(rootClassName, className, { [`${rootClassName}--disabled`]: disabled }),
    };
};
