import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { DataGridColumnOptions, DataGridContextState, DataGridContextProps } from './DataGridContextTypes';
import { ResizableContext } from '../contexts';
import { DataTableColumnProps } from './components/DataGridColumn';
import { VariableSizeList } from 'react-window';
import { DataGridRow } from './components/DataGridRow';
import { DataGridLayout } from './components/DataGridLayout';
import { DataGridCustomScrollbar } from './components/DataGridCustomScrollbar';
import { useVariableSizeListAdapter } from '../hooks/useVariableSizeListAdapter';

export const DataGridContext = React.createContext<DataGridContextState>({
    items: [],
    handleSortChange: () => {},
    sorting: null,
    columns: [],
    isLoading: false,
    top: 0,
    height: 0,
    updateSizes: () => {},
    listRef: null,
    onClickRow: () => {},
    scrollableRef: null,
});

export const DataGridContextView: React.FC<React.PropsWithChildren<DataGridContextProps>> = (props): JSX.Element => {
    const { className, children, columns, renderExpandedRowContent, items, onLoadMore, isLoading, sorting, onSortChange, onColumnResized, height = 100, onClickRow, loadingText } = props;
    const [isInitiated, setIsInitiated] = useState(false);
    const { top, listRef, updateSizes, getSize, scrollableRef, ...fixedSizeListProps } = useVariableSizeListAdapter({ height, itemsCount: items.length, onScrollEnd: onLoadMore });
    const itemsCountWithPreloaderRow = isLoading && loadingText ? items?.length + 1 : items.length;

    const childrenToColumns = useCallback((): DataGridColumnOptions[] => {
        return React.Children.map(children, (child: React.ReactElement<DataTableColumnProps>) => {
            return child.props;
        });
    }, [children]);

    const tableColumns = useMemo((): DataGridColumnOptions[] => {
        const normalizedColumns = columns || childrenToColumns() || [];
        const isActionsColumnExist = normalizedColumns.find((column) => column.actionsColumn);
        if (!isActionsColumnExist) {
            normalizedColumns.push({
                actionsColumn: true,
            });
        }

        return normalizedColumns.map((column) =>
            !column.actionsColumn
                ? column
                : {
                      ...column,
                      cellProps: { ...column.cellProps, actionsColumn: true },
                      headCellProps: { ...column.headCellProps, actionsColumn: true },
                  },
        );
    }, [columns, childrenToColumns]);

    useLayoutEffect(() => {
        setIsInitiated(true);
    }, [isInitiated]);

    return (
        <ResizableContext onResized={onColumnResized} isWidthFixed={props.isWidthFixed}>
            <DataGridContext.Provider
                value={{
                    items,
                    handleSortChange: onSortChange,
                    sorting,
                    columns: tableColumns,
                    isLoading,
                    top,
                    listRef,
                    height,
                    updateSizes,
                    onClickRow,
                    renderExpandedRowContent,
                    scrollableRef,
                    loadingText,
                }}
            >
                <VariableSizeList
                    height={height}
                    width="100%"
                    itemCount={itemsCountWithPreloaderRow}
                    itemSize={getSize}
                    innerElementType={DataGridLayout}
                    className={className}
                    outerElementType={DataGridCustomScrollbar}
                    outerRef={scrollableRef}
                    {...fixedSizeListProps}
                >
                    {DataGridRow}
                </VariableSizeList>
            </DataGridContext.Provider>
        </ResizableContext>
    );
};
