import { useEffect, useState } from 'react';

export const useDelayedCallback = (callback: VoidFunction, delay = 200) => {
    const [isExecuting, setIsExecuting] = useState<boolean>(false);

    const stop = () => {
        setIsExecuting(false);
    };

    const start = () => {
        setIsExecuting(true);
    };

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (isExecuting && callback) {
            timeout = setTimeout(() => {
                callback();
            }, delay);
        } else {
            clearTimeout(timeout);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isExecuting]);

    return { start, stop };
};
