import classNames from 'classnames';
import { CheckboxProps } from '../CheckboxTypes';

const rootClassName = 'ui-checkbox';

type Params = Pick<CheckboxProps, 'className' | 'checked' | 'checkedPartially' | 'disableExtraActionArea'>;

export const useClasses = (params: Params) => {
    const { className, checked, checkedPartially, disableExtraActionArea } = params;
    return {
        root: classNames(
            rootClassName,
            {
                [`${rootClassName}--checked`]: checked,
                [`${rootClassName}--checked-partially`]: checkedPartially,
                [`${rootClassName}--disable-extra-action-area`]: disableExtraActionArea,
            },
            className,
        ),
        checkMark: {
            root: classNames(`${rootClassName}__check-mark`),
            icon: `${rootClassName}__check-mark__icon`,
        },
    };
};
