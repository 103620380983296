import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../../../common/utils/dataId';
import { Select } from '../../../../../../components/Ui/Inputs/Select';
import { Typography } from '../../../../../../components/Ui/Typography';
import { Button } from '../../../../../../components/Ui/Button';
import Icon, { ICONS } from '../../../../../../components/Icon/Icon';
import { getCustomFields, getDimensionsName, rootClassName } from './ExpandedRowContentHelper';
import { Props } from './ExpandedRowContentType';

import './ExpandedRowContent.scss';

export const ExpandedRowContent: React.FC<Props> = (props) => {
    const { invoiceStatus, data, isMobile, isProducItemModuleActive, dataId } = props;

    const { t } = useTranslation();

    const fields = useMemo(() => getCustomFields(invoiceStatus, data?.TransactionRowsDimensions), []);

    return (
        <div className={rootClassName}>
            {isProducItemModuleActive && (
                <div className={`${rootClassName}__field-row`}>
                    {!isMobile && (
                        <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                            {t('component.transactionRows.BuyerCode')}
                        </Typography>
                    )}
                    <div className={`${rootClassName}__field-data`}>
                        <Select
                            label={isMobile && t('component.transactionRows.BuyerCode')}
                            placeholder={t('component.transactionRows.BuyerCode')}
                            options={[{ value: data.BuyerProductId || '', label: data.BuyerProductName || '' }]}
                            value={data.BuyerProductId}
                            onSelect={() => null}
                        />
                    </div>
                </div>
            )}
            <div className={`${rootClassName}__field-row`}>
                {!isMobile && (
                    <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                        {t('component.transactionRows.account')}
                    </Typography>
                )}
                <div className={`${rootClassName}__field-data`}>
                    <Select
                        label={isMobile && t('component.transactionRows.account')}
                        placeholder={t('component.transactionRows.chooseAnAccount')}
                        options={[{ value: data.Account?.Id.toString() || '', label: data?.Account?.Code + ' - ' + data?.Account?.Description }]}
                        value={data?.Account?.Id.toString()}
                        onSelect={() => null}
                    />
                </div>
            </div>
            <div className={`${rootClassName}__field-row`}>
                {!isMobile && (
                    <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                        {t('component.transactionRows.VAT')}
                    </Typography>
                )}
                <div className={`${rootClassName}__field-data`}>
                    <Select
                        label={isMobile && t('component.transactionRows.VAT')}
                        placeholder={t('component.transactionRows.chooseAVAT')}
                        options={[{ value: data?.VatCode?.Id?.toString() || '', label: data?.VatCode?.Code + ' - ' + data?.VatCode?.Description }]}
                        value={data?.VatCode?.Id?.toString()}
                        onSelect={() => null}
                    />
                </div>
            </div>
            {fields.map((field) => (
                <div className={`${rootClassName}__field-row`} key={field?.CustomCostObjectiveId}>
                    {!isMobile && (
                        <Typography className={`${rootClassName}__field-name`} variant="body-md" element="div">
                            {`${field?.CustomCostObjective?.Description}${field?.IsMandatory ? '*' : ''}`}
                        </Typography>
                    )}
                    <div className={`${rootClassName}__field-data`}>
                        {field.CustomCostObjective?.DimensionsCount > 0 ? (
                            <Select
                                label={isMobile && `${field?.CustomCostObjective?.Description}${field?.IsMandatory ? '*' : ''}`}
                                placeholder={t('component.transactionRows.chooseADimension')}
                                options={[{ value: field?.Id?.toString() || '', label: getDimensionsName(field?.Dimension) }]}
                                value={field?.Id?.toString()}
                                onSelect={() => null}
                            />
                        ) : field?.Dimension ? (
                            <Typography dataId={createDataId(dataId, 'dropdown', 'dimensionsText')} className={`${rootClassName}__field-dimensions-text`} variant="body-md" element="div">
                                {getDimensionsName(field?.Dimension)}
                            </Typography>
                        ) : (
                            <Typography dataId={createDataId(dataId, 'dropdown', 'noDimensions')} className={`${rootClassName}__field-no-dimensions`} variant="body-md" element="div">
                                {t('component.transactionRows.NoDimensionsAvailable')}
                            </Typography>
                        )}
                    </div>
                </div>
            ))}
            <Button className={`${rootClassName}__field-add-new`} size="sm" variant="outlined" startIcon={<Icon iconName={ICONS.PLUS_THICK_24} />}>
                {t('component.transactionRows.cco.addNew')}
            </Button>
        </div>
    );
};
