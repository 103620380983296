import { cloneDeep } from 'lodash-es';

import { InvoiceStatus } from '../../../../../../common/constants/appConstants';
import { DimensionDTO, TransactionRowDimensionDTO } from '../../../../../../services/types/ApiTypes';

export const rootClassName = 'transaction-expanded-row';

export const getCustomFields = (invoiceStatus: number, fields: TransactionRowDimensionDTO[]): TransactionRowDimensionDTO[] => {
    const fieldList = cloneDeep(fields);
    const invoiceStatuses = [InvoiceStatus.PendingExport, InvoiceStatus.Exported, InvoiceStatus.NotForExport];
    if (invoiceStatuses.includes(invoiceStatus)) {
        fieldList.sort((a, b) => a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo);
        return fieldList;
    }

    fieldList.sort((a, b) => {
        if (!a.CustomCostObjective || !b.CustomCostObjective) {
            return 0;
        }
        return a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo;
    });
    return fieldList;
};

export const getDimensionsName = (dimension: DimensionDTO) => {
    return `${dimension?.Code} - ${dimension?.Description}`;
};
