import { TFunction } from 'i18next';

import api from '../../../../services/ApiServices';
import { CustomCostObjectiveFullDTO, TransactionRowDimensionDTO, TransactionRowDTO } from '../../../../services/types/ApiTypes';
import { DataGridColumnOptions } from '../../../../components/Ui/DataGrid';
import { formatMoneyToShowSeparators } from '../invoice-header/utils';
import { trimAfterComma } from '../invoice-rows/InvoiceRowsHelper';
import { TransactionRowTableObject } from './TransactionRowsTypes';

export const dataId = 'transaction-rows';

export const rootClassName = 'transaction-rows';

const columnTitlesMap: Record<string, string> = {
    Description: 'component.transactionRows.table.column.description',
    SumWithoutVat: 'component.transactionRows.table.column.sum',
    VAT: 'component.transactionRows.table.column.vat',
    Total: 'component.transactionRows.table.column.total',
};

const columnWidthsMap: Record<string, number> = {
    Description: 40,
    SumWithoutVat: 20,
    VAT: 20,
    Total: 20,
};

const columnJustifysMap: Record<string, 'start' | 'end' | 'center'> = {
    Description: 'start',
    SumWithoutVat: 'end',
    VAT: 'end',
    Total: 'end',
};

const createColumn = (index: number, t: TFunction, options?: DataGridColumnOptions): DataGridColumnOptions => {
    return {
        label: t?.(columnTitlesMap[options.field]),
        columnName: `${options.field}_${index}`,
        headCellProps: { ...options.headCellProps, justify: columnJustifysMap[options.field], hideOverflow: true },
        cellProps: { ...options.cellProps, justify: columnJustifysMap[options.field], hideOverflow: true, className: index === 0 && 'transaction-rows__cell-description' },
        resizable: false,
        sortable: false,
        width: columnWidthsMap[options.field],
        ...options,
    };
};

export const createColumns = (isMobile: boolean, t: TFunction): DataGridColumnOptions[] => {
    const fields = isMobile ? ['Description'] : ['Description', 'SumWithoutVat', 'VAT', 'Total'];
    const columns: DataGridColumnOptions[] = [];
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i % fields.length];
        columns.push(createColumn(i, t, { field }));
    }
    return columns;
};

export const createRowObject = (row: TransactionRowDTO): TransactionRowTableObject => {
    const sumWithoutVat = formatMoneyToShowSeparators(trimAfterComma(row?.SumWithoutVat, 4, 2), true);
    const vat = `${formatMoneyToShowSeparators(trimAfterComma(row?.VAT, 4, 2), true)} (${trimAfterComma(String(row?.VatRate), 2)}%)`;
    const total = formatMoneyToShowSeparators(trimAfterComma(row?.Total, 4, 2), true);
    return {
        Description: `${row.OrderNo}.${row.Description}`,
        SumWithoutVat: sumWithoutVat,
        VAT: vat,
        Total: total,
    };
};

export const getTransactionsRowDimensions = (rows: TransactionRowDTO[], customCostObjectives: CustomCostObjectiveFullDTO[]): TransactionRowDTO[] => {
    return rows?.map((transactionRow) => {
        if (customCostObjectives.length > 0 && transactionRow?.Id > 0) {
            for (const cco of customCostObjectives) {
                const transactionRowHasCustomCostObjective = transactionRow.TransactionRowsDimensions?.some((customField) => {
                    const match = customField.CustomCostObjective.Id === cco.Id;
                    if (match) {
                        customField.CustomCostObjective.DimensionsCount = cco.DimensionsCount;
                    }
                    return match;
                });
                if (!transactionRowHasCustomCostObjective && (cco.IsMandatory || cco.IsVisible)) {
                    transactionRow.TransactionRowsDimensions.push({
                        IsMandatory: cco.IsMandatory,
                        CustomCostObjective: cco,
                        CustomCostObjectiveId: cco.Id,
                        IsVisible: cco.IsVisible,
                        DimensionId: cco.Id,
                    });
                }
            }
        }
        return transactionRow;
    });
};

//TODO: Account field list
export const getAccountDistributionItemCustomCostObjectives = async (costObjectives: CustomCostObjectiveFullDTO[]): Promise<TransactionRowDimensionDTO[]> => {
    try {
        const items: TransactionRowDimensionDTO[] = [];
        const result = await api.customCostObjective.getByDescriptionPart('', false);
        if (result?.data?.length > 0) {
            const unusedCustomCostObjectives = result.data.filter((field) => {
                const cco = costObjectives.find((customField) => {
                    return field.Id === customField.Id;
                });
                if (cco?.IsMandatory || cco?.IsVisible) {
                    return false;
                }
                field.DimensionsCount = cco?.DimensionsCount || 0;
                return true;
            });
            for (const cco of unusedCustomCostObjectives) {
                items.push({
                    IsMandatory: cco.IsMandatory,
                    CustomCostObjective: cco,
                    CustomCostObjectiveId: cco.Id,
                    IsVisible: cco.IsVisible,
                    DimensionId: cco.Id,
                });
            }
            items.sort((a, b) => a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo);
        }
        return items;
    } catch (e) {
        console.error(e);
        return [];
    }
};
