import React, { useRef } from 'react';
import { CheckboxProps } from './CheckboxTypes';
import { useClasses } from './hooks/useClasses';
import { useDataId } from './hooks/useDataId';

import Icon, { ICONS } from '../../../Icon/Icon';

import './Checkbox.scss';

export const CheckboxView: React.FC<CheckboxProps> = (props) => {
    const { className, checked, checkedPartially, onChange, dataId, disableExtraActionArea, ...rest } = props;
    const classes = useClasses({ className, checked, checkedPartially, disableExtraActionArea });
    const dataIds = useDataId(dataId);
    const inputRef = useRef<HTMLInputElement>(null);

    const checkIcon = checkedPartially ? ICONS.UI_ICON_LINE : ICONS.UI_ICON_CHECK;

    const handleClickCheckMark: React.MouseEventHandler<HTMLSpanElement> = (e) => {
        const input = inputRef.current;
        if (!input) {
            return;
        }
        input.checked = checkedPartially ? true : !checked;
        onChange?.(input.checked, e, input);
    };

    const handleKeyUp: React.KeyboardEventHandler<HTMLSpanElement> = (e) => {
        const keys = ['Enter', ' '];
        const keyCodes = [13, 32];
        if (keys.includes(e.key) || keyCodes.includes(e.keyCode)) {
            handleClickCheckMark((e as unknown) as React.MouseEvent<HTMLSpanElement>);
        }
    };

    return (
        <span className={classes.root} onClick={handleClickCheckMark} tabIndex={0} onKeyUp={handleKeyUp} data-id={dataIds?.root}>
            <input type="checkbox" checked={checked} hidden {...rest} ref={inputRef} readOnly data-id={dataIds?.input} />
            <span className={classes.checkMark.root} data-id={dataIds?.checkmarkContainer}>
                {(checked || checkedPartially) && <Icon iconName={checkIcon} className={classes.checkMark.icon} />}
            </span>
        </span>
    );
};
