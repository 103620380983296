import React, { useCallback, useState } from 'react';
import { TableRowProps } from './TableRowTypes';
import { useClasses } from './hooks/useClasses';
import { TableCellProps } from '../TableCell';

import './TableRow.scss';

const TableRowForwarded = React.forwardRef<HTMLTableRowElement, TableRowProps>(function DataTableRowView(props, ref) {
    const { className, children, theadRow, isExpandableRow, ...rowProps } = props;
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const classes = useClasses({ className, theadRow, isActive, isHovered, isExpandableRow });

    const handleMouseOver: React.MouseEventHandler<HTMLTableCellElement> = useCallback(() => {
        setIsHovered(true);
    }, []);
    const handleMouseLeave: React.MouseEventHandler<HTMLTableCellElement> = useCallback(() => {
        setIsHovered(false);
    }, []);
    const handleMouseDown: React.MouseEventHandler<HTMLTableCellElement> = useCallback(() => {
        setIsActive(true);
    }, []);
    const handleMouseUp: React.MouseEventHandler<HTMLTableCellElement> = useCallback(() => {
        setIsActive(false);
    }, []);

    return (
        <tr className={classes.root} ref={ref} {...rowProps}>
            {React.Children.map(children, (child: React.ReactElement<TableCellProps>) => {
                const props: TableCellProps = { ...child.props, onMouseOver: handleMouseOver, onMouseLeave: handleMouseLeave };
                if (!child.props.actionsColumn) {
                    props.onMouseDown = handleMouseDown;
                    props.onMouseUp = handleMouseUp;
                }
                return React.cloneElement(child, props);
            })}
        </tr>
    );
});

export const TableRowView = React.memo(TableRowForwarded);
