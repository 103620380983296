import React from 'react';
import { useClasses } from './hooks/useClasses';
import { DataTableTHeadProps } from './TableHeadTypes';
import './TableHead.scss';

export const TableHeadView: React.FC<DataTableTHeadProps> = (props) => {
    const { children, className } = props;
    const classes = useClasses({ className });

    return <thead className={classes.root}>{children}</thead>;
};
