import classNames from 'classnames';
import { TableRowActionsProps } from '../TableRowActionsTypes';

const rootClassName = 'ui-table-row-actions';

type Params = Pick<TableRowActionsProps, 'className'>;

export const useClasses = (params: Params) => {
    const { className } = params;
    return { root: classNames(rootClassName, className) };
};
