import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import { FixedSizeListProps, VariableSizeList } from 'react-window';

type Params = {
    height?: number;
    itemsCount?: number;
    onScrollEnd?: VoidFunction;
};

type VariableSizeListWithPrivateProperties = VariableSizeList & { _getItemStyle?: (startIndex: number) => { top: number } | undefined };

export type UseVariableSizeListAdapter = {
    onScroll: FixedSizeListProps['onScroll'];
    scrollableRef: MutableRefObject<Scrollbar & HTMLDivElement>;
    onItemsRendered: FixedSizeListProps['onItemsRendered'];
    ref: (el: VariableSizeList) => void;
    top: number;
    listRef: MutableRefObject<VariableSizeListWithPrivateProperties>;
    innerRef: (el: HTMLDivElement) => void;
    updateSizes: (index: number, size: number) => void;
    getSize: (index: number) => number;
    height: number;
};

export const useVariableSizeListAdapter = (params: Params): UseVariableSizeListAdapter => {
    const { height, itemsCount, onScrollEnd } = params;
    const scrollableRef = useRef<Scrollbar & HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<VariableSizeListWithPrivateProperties | null>();
    const sizesMap = useRef<Map<number, number>>(new Map());

    const [top, setTop] = useState(0);
    const [prevTop, setPrevTop] = useState(0);
    const [maxHeight, setMaxHeight] = useState(0);

    const handleScroll: UseVariableSizeListAdapter['onScroll'] = ({ scrollOffset }) => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTop = scrollOffset;
        }
    };

    const handleItemsRendered: UseVariableSizeListAdapter['onItemsRendered'] = (props) => {
        const { visibleStopIndex } = props;
        const style = listRef.current && listRef.current._getItemStyle(props.overscanStartIndex);

        if (visibleStopIndex === itemsCount - 1) {
            onScrollEnd?.();
            setPrevTop(top);
        }
        setTop(() => {
            if (style?.top === 0) {
                return style.top;
            }
            return style?.top || prevTop || 0;
        });
    };

    const handleRef: UseVariableSizeListAdapter['ref'] = (el) => {
        listRef.current = el;
    };

    const handleInnerRef = (el: HTMLDivElement) => {
        if (el && !innerRef?.current) {
            const tableHeight = el?.children[0]?.clientHeight;
            if (tableHeight && tableHeight < height) {
                setMaxHeight(tableHeight);
            } else {
                setMaxHeight(height);
            }
        }
        innerRef.current = el;
    };

    const updateSizes: UseVariableSizeListAdapter['updateSizes'] = useCallback((index, size) => {
        if (sizesMap.current.get(index) !== size) {
            sizesMap.current.set(index, size);
            listRef.current?.resetAfterIndex(index);
        }
    }, []);

    const getSize: UseVariableSizeListAdapter['getSize'] = useCallback(
        (index: number) => {
            return sizesMap.current.get(index) || 50;
        },
        [listRef.current],
    );

    return { onScroll: handleScroll, scrollableRef, onItemsRendered: handleItemsRendered, ref: handleRef, innerRef: handleInnerRef, top, listRef, height: maxHeight, updateSizes, getSize };
};
