import classNames from 'classnames';
import { TableHeadCellProps } from '../TableHeadCellTypes';

const rootClassName = 'ui-table-head-cell';

type Params = Pick<TableHeadCellProps, 'className' | 'actionsColumn' | 'justify'>;

export const useClasses = (params: Params) => {
    const { className, actionsColumn, justify } = params;
    return {
        root: classNames(rootClassName, { [`${rootClassName}--actions-column`]: actionsColumn }, `${rootClassName}--justify-${justify}`, className),
        resizeTrigger: `${rootClassName}__resize-trigger`,
        sortIcon: `${rootClassName}__sort-icon`,
        content: {
            root: `${rootClassName}__content`,
            label: `${rootClassName}__content__label`,
        },
    };
};
