import classNames from 'classnames';
import { InputBaseProps } from '../InputBaseTypes';

type Params = Pick<InputBaseProps, 'className' | 'required' | 'error' | 'disabled' | 'prefix' | 'focused'>;

const rootClassName = 'ui-input-base';
export const useClasses = (params: Params) => {
    const { className, prefix, focused, error, disabled } = params;
    return {
        root: classNames(
            rootClassName,
            {
                [`${rootClassName}--with-prefix`]: prefix,
                [`${rootClassName}--focused`]: focused,
                [`${rootClassName}--error`]: error,
                [`${rootClassName}--disabled`]: disabled,
            },
            className,
        ),
        content: {
            root: `${rootClassName}__content`,
            inputContainer: `${rootClassName}__content__input-container`,
        },
        prefix: `${rootClassName}__prefix`,
    };
};
