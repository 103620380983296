import { DataGridCustomScrollbarProps } from '../DataGridCustomScrollbarTypes';
import classNames from 'classnames';

const rootClassName = 'ui-datagrid-scrollbars';

type Params = Pick<DataGridCustomScrollbarProps, 'className'>;

export const useClasses = (params: Params) => {
    const { className } = params;
    return {
        root: classNames(rootClassName, className),
    };
};
