import React from 'react';

import { Typography, TypographyProps } from '../Typography';
import { TooltipTrigger } from '../TooltipTrigger';
import { DisplayDataProps } from './DisplayDataTypes';
import { useClasses } from './useClasses';
import './DisplayDataStyles.scss';

const valuesSeparator = '•';

export const DisplayDataView = (props: DisplayDataProps): JSX.Element => {
    const { label, infoTooltip, hideOverflow, dataId, justifyContent, className, size = 'sm', ...rootProps } = props;
    const classes = useClasses({ className, justifyContent, hideOverflow });
    const isMultiplyValue = Array.isArray(props.value);
    const value = Array.isArray(props.value) ? props.value.join(` ${valuesSeparator} `) : props.value;
    const valueVariant: TypographyProps['variant'] = size === 'md' ? 'display-md' : 'body-lg';

    return (
        <div className={classes.root} data-id={dataId} {...rootProps}>
            <div className={classes.label}>
                <Typography element="span" variant="label-md">
                    {label}
                </Typography>
                {infoTooltip && <TooltipTrigger size="sm" />}
            </div>

            {!isMultiplyValue && (
                <Typography element="span" variant={valueVariant} className={classes.value.single} title={hideOverflow && value}>
                    {value}
                </Typography>
            )}

            {Array.isArray(props.value) && (
                <div className={classes.value.multiple}>
                    {props.value.map((item, index) => (
                        <>
                            <Typography element="span" variant={valueVariant}>
                                {item}
                            </Typography>
                            {index < props.value.length - 1 && (
                                <Typography element="span" variant={valueVariant}>
                                    {valuesSeparator}
                                </Typography>
                            )}
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};
