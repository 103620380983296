import { TablePreloaderRowProps } from '../TablePreloaderRowTypes';
import classNames from 'classnames';

const rootClassName = 'ui-table-preloader-row';

type Params = Pick<TablePreloaderRowProps, 'className'>;

export const useClasses = (params: Params) => {
    const { className } = params;
    return {
        root: classNames(rootClassName, className),
        cellContent: `${rootClassName}__cell__content`,
    };
};
