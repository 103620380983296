import React, { useCallback, useEffect, useState } from 'react';
import { ResizableContextType, ResizableContextProps, ResizeData } from './ResizableContextTypes';

export const resizableContextDefaultValue: ResizableContextType = {
    getResizeTriggerProps: () => ({}),
    getRootProps: () => ({}),
    lastResizedOn: 0,
};

export const ResizableContext = React.createContext<ResizableContextType>(resizableContextDefaultValue);

export const ResizableContextVIew: React.FC<ResizableContextProps> = (props) => {
    const { children, onResized, isWidthFixed } = props;
    const [resizeData, setResizeData] = useState<ResizeData | null>(null);
    const [minWidth, setMinWidth] = useState(0);
    const [lastResizedOn, setLastResizedOn] = useState<number | null>(Date.now());

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!resizeData) {
                return;
            }
            const startPos = resizeData?.startPos;
            const parentElement = resizeData?.parentElementSizes;
            if (startPos && parentElement) {
                const { width } = parentElement;
                const dx = e.clientX - startPos.x;
                let elementWidth = width + dx;
                if (elementWidth < minWidth) {
                    elementWidth = minWidth;
                }
                resizeData.element.style.minWidth = `${elementWidth}px`;
                resizeData.element.style.maxWidth = `${elementWidth}px`;
                resizeData.element.style.width = `${elementWidth}px`;
            }
        },
        [resizeData],
    );

    const handleMouseDown: React.MouseEventHandler<HTMLElement> = useCallback(
        (e) => {
            const trigger = e.target as HTMLDivElement;
            const parent = trigger.parentElement as HTMLTableCellElement;
            const startPos = {
                x: e.clientX,
                y: e.clientY,
            };
            const styles = window.getComputedStyle(parent);
            const w = parseInt(styles.width, 10);
            const h = parseInt(styles.height, 10);
            trigger.style.opacity = '1';
            setResizeData({ startPos, parentElementSizes: { width: w, height: h }, element: parent, trigger });
        },
        [resizeData],
    );

    const handleMouseUp = useCallback(
        (e: MouseEvent) => {
            if (resizeData) {
                resizeData.trigger.style.opacity = '0';
            }
            const prev = resizeData?.parentElementSizes?.width;
            const newSize = parseInt(resizeData?.element?.style.width, 10);
            if (!isNaN(prev) && !isNaN(newSize)) {
                const elementId = resizeData.element.getAttribute('data-resizable-id');
                setLastResizedOn(Date.now());
                onResized?.({ elementId, size: newSize }, e);
            }

            setResizeData(null);
        },
        [resizeData],
    );

    const handleClickResizeTrigger: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    const getResizeTriggerProps: ResizableContextType['getResizeTriggerProps'] = useCallback(() => {
        return {
            onMouseDown: handleMouseDown,
            onClick: handleClickResizeTrigger,
        };
    }, [handleMouseDown, handleClickResizeTrigger]);

    const getRootProps: ResizableContextType['getRootProps'] = useCallback(
        (widthOptions) => {
            const { id, defaultWidth, minWidth } = widthOptions;
            if (minWidth && !isWidthFixed) {
                setMinWidth(minWidth);
            }
            const initialSize = isWidthFixed ? `${defaultWidth}%` : `${Math.max(minWidth, defaultWidth)}px`;
            const props: React.HTMLAttributes<HTMLElement> & {
                'data-resizable-id'?: string;
            } = {
                style: {
                    minWidth: initialSize,
                    width: initialSize,
                    maxWidth: initialSize,
                },
            };
            if (id) {
                props['data-resizable-id'] = id;
            }
            return props;
        },
        [minWidth],
    );

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    return <ResizableContext.Provider value={{ getResizeTriggerProps, getRootProps, lastResizedOn }}>{children}</ResizableContext.Provider>;
};
