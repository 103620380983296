import classNames from 'classnames';

import { UseClassesProps } from './DisplayDataTypes';

const rootClassName = 'ui-display-data';

export const useClasses = (props: UseClassesProps) => {
    const { className, hideOverflow } = props;
    return {
        root: classNames(rootClassName, className, {
            [`${rootClassName}--hide-overflow`]: hideOverflow,
            [`${rootClassName}--justify-end`]: props.justifyContent === 'end',
        }),
        label: `${rootClassName}__label`,
        value: {
            single: classNames(`${rootClassName}__value`, `${rootClassName}__value--single`, { [`${rootClassName}__value--single--hide-overflow`]: hideOverflow }),
            multiple: `${rootClassName}__value--multiple`,
        },
    };
};
