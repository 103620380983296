import React, { forwardRef } from 'react';
import { InputLabelProps } from './InputLabelTypes';
import { Typography } from '../../../Typography';
import { useClasses } from './hooks/useClasses';
import { TooltipTrigger } from '../../../TooltipTrigger';

import './InputLabelView.scss';
import { useDataId } from './hooks/useDataId';

export const InputLabelView = forwardRef(function InputLabel(props: InputLabelProps, ref: React.Ref<HTMLDivElement>) {
    const { className, required, infoTooltip, disabled, children, variant = 'label-lg', dataId } = props;
    const classes = useClasses({ className, disabled });
    const dataIds = useDataId(dataId);

    return (
        <div className={classes.root} ref={ref} data-id={dataIds?.root}>
            <Typography variant={variant} dataId={dataIds?.labelText}>
                {children}
            </Typography>
            {required && (
                <span>
                    <b>*</b>
                </span>
            )}
            {Boolean(infoTooltip) && <TooltipTrigger size="sm" dataId={dataIds?.tooltip} />}
        </div>
    );
});
