import classNames from 'classnames';
import React from 'react';
import Icon, { ICONS, IconSize } from '../Icon/Icon';
import { iconSizesMap, rootClassName, useClasses } from './CircularProgressHelper';
import { CircularProgressProps } from './CircularProgressTypes';

import './CircularProgressView.scss';

export const CircularProgressView: React.FC<CircularProgressProps> = (props) => {
    const { className, size = 'sm', iconProps } = props;
    const classes = useClasses({ size });
    return (
        <span className={classNames(rootClassName, className, classes.size)}>
            <Icon {...iconProps} iconName={ICONS.CIRCULAR_PROGRESS_SM} size={iconSizesMap.get(size) || IconSize.SM} />
        </span>
    );
};
