import React from 'react';
import { MenuItem } from 'react-aria-menubutton';

import { Typography, TypographyProps } from '../../../../Typography';

import { SelectOptionProps } from './SelectOptionTypes';
import { useDataId } from './hooks/useDataId';
import { useClasses } from './hooks/useClasses';

import './SelectOption.scss';

export const SelectOptionView: React.FC<React.PropsWithChildren<SelectOptionProps>> = (props) => {
    const { dataId, size = 'md', icon, children, disableIconContainer, ...menuItemProps } = props;

    const classes = useClasses({ selected: props.selected, size });
    const dataIds = useDataId(dataId);

    const typographyVariant: TypographyProps['variant'] = size === 'md' ? 'body-lg' : 'body-md';

    return (
        <MenuItem className={classes.root} data-id={dataIds?.root} {...menuItemProps} tabIndex={0}>
            {icon && disableIconContainer ? icon : <span className={classes.icon}>{icon}</span>}
            {typeof children === 'string' ? (
                <Typography variant={typographyVariant} element="span">
                    {props.children}
                </Typography>
            ) : (
                <span className={classes.content}>{children}</span>
            )}
        </MenuItem>
    );
};
