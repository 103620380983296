import { createDataId } from '../../../../../../common/utils/dataId';

export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }
    return {
        root: dataId,
        label: createDataId(dataId, 'label'),
        helperText: createDataId(dataId, 'helper-text'),
        content: createDataId(dataId, 'content'),
        prefix: createDataId(dataId, 'prefix'),
    };
};
