import React, { ChangeEventHandler, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMenu from 'react-aria-menubutton';
import _ from 'lodash-es';

import Icon, { ICONS } from '../../../Icon/Icon';
import { TextInput } from '../TextInput';
import { useClasses } from './hooks/useClasses';
import { SelectOptions } from './components/SelectOptions';
import { SelectOption } from './components/SelectOption';
import { SelectState, SelectProps } from './SelectTypes';
import { useDataId } from './hooks/useDataId';
import { Checkbox } from '../Checkbox';

import './Select.scss';

const initialState: SelectState = {
    isMenuOpen: false,
    isEdit: false,
    isInputChanged: false,
};

export const SelectView: React.FC<SelectProps> = (props) => {
    const {
        label,
        prefix,
        helperText,
        error,
        errorPlacement = 'vertical',
        endContent,
        value,
        onChange,
        placeholder,
        startContent,
        className,
        onSelect,
        options,
        optionsActions,
        inputValue,
        disabled,
        clearInputValue,
        selectedCount,
        optionsHeaderText,
        textInputProps = {},
        selectOptionsProps = {},
        required,
        infoTooltip,
        dataId,
        inputSize = 'md',
        multiselect,
        onOptionsClose,
        onOptionsOpen,
        clearAllButtonLabel,
        showClearAllButton,
        onClickClearAll,
    } = props;
    const [selectInputState, setSelectInputState] = useState<SelectState>(initialState);

    const classes = useClasses({ className, error, errorPlacement, helperText, inputSize });
    const inputRef = useRef<HTMLInputElement>(null);
    const menuButtonRef = useRef(null);
    const dataIds = useDataId(dataId);
    const isMenuToggleButtonDisabled = disabled || selectInputState.isEdit;
    const currentTextInputMode: SelectProps['mode'] = onChange && selectInputState.isEdit ? 'edit' : 'view';
    const [isMouseOver, setIsMouseOver] = useState(false);

    const selectedInputValue = useMemo(() => {
        const selectedItems = options.filter((child) => child?.value === value);
        const [item] = selectedItems;

        return (selectInputState.isInputChanged ? inputValue : item?.label) || '';
    }, [value, inputValue, options, selectInputState.isInputChanged]);

    const getSelectOptionHandlers = useCallback(
        (value: string) => {
            const handlers: {
                onClick?: MouseEventHandler<HTMLElement>;
            } = {};
            if (multiselect) {
                handlers.onClick = (e) => {
                    onSelect(value, e);
                };
            }
            return handlers;
        },
        [multiselect, menuButtonRef.current],
    );

    const isOptionChecked = useCallback(
        (optionValue: string) => {
            if (!Array.isArray(value)) {
                return false;
            }
            return value.includes(optionValue);
        },
        [value],
    );

    const closeOptions = () => {
        setSelectInputState((prev) => {
            clearInputValue?.();
            const menuButtonBlurHandler = menuButtonRef?.current?.context?.ambManager?.handleBlur;
            menuButtonBlurHandler?.();
            return { ...prev, isEdit: false, isInputChanged: false, isMenuOpen: false };
        });
    };

    const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (e) => {
        setSelectInputState((prev) => ({ ...prev, isInputChanged: true }));
        onChange?.(e);
    };

    const handleSelectOption: ReactMenu.WrapperProps<any>['onSelection'] = (newValue: string, event) => {
        onSelect(newValue, event);
    };

    const handleToggleMenu = (wrapperState: ReactMenu.WrapperState) => {
        const { isOpen } = wrapperState;
        setSelectInputState((prev) => {
            const newState = { ...prev, isMenuOpen: isOpen };
            if (props.onChange) {
                newState.isEdit = isOpen;
            }
            if (!isOpen) {
                newState.isInputChanged = false;
                clearInputValue?.();
            }
            return newState;
        });
        isOpen ? onOptionsOpen?.() : onOptionsClose?.();
    };

    const handleBlurMenuButton: React.FocusEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
    };

    const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        const isEscapeKey = e.key === 'Escape' || e?.keyCode === 27;

        if (isEscapeKey) {
            closeOptions();
        }
    };

    const handleOptionsBlur = useCallback(() => {
        setIsMouseOver(false);
    }, []);

    const handleOptionsMouseOver = useCallback(() => {
        setIsMouseOver(true);
    }, []);

    useEffect(() => {
        if (inputRef.current && props.onChange) {
            selectInputState.isMenuOpen ? inputRef.current.focus() : inputRef.current.blur();
        }
    }, [selectInputState.isMenuOpen, props.onChange]);

    return (
        <ReactMenu.Wrapper className={classes.root} onSelection={handleSelectOption} onMenuToggle={handleToggleMenu} data-id={dataIds?.root} closeOnSelection={!multiselect} closeOnBlur={!isMouseOver}>
            <ReactMenu.Button className={classes.triggerButton} disabled={isMenuToggleButtonDisabled} onBlur={handleBlurMenuButton} ref={menuButtonRef}>
                <TextInput
                    ref={inputRef}
                    value={selectedInputValue}
                    focused={selectInputState.isMenuOpen}
                    error={error}
                    placeholder={!selectInputState.isMenuOpen && placeholder}
                    onChange={handleChangeInput}
                    disabled={disabled}
                    required={required}
                    infoTooltip={infoTooltip}
                    inputSize={inputSize}
                    endContent={
                        <div className={classes.endContent}>
                            {endContent && endContent}
                            {!disabled && <Icon name={ICONS.CHEVRON_DOWN_24} />}
                        </div>
                    }
                    startContent={startContent}
                    label={label}
                    helperText={helperText}
                    prefix={prefix}
                    errorPlacement={errorPlacement}
                    mode={currentTextInputMode}
                    caretPositionInEditMode="start"
                    dataId={dataIds?.input}
                    inputProps={{
                        onKeyUp: handleKeyUp,
                    }}
                    {...textInputProps}
                />
            </ReactMenu.Button>

            <SelectOptions
                value={value}
                className={classes.menuWrapper}
                actions={optionsActions}
                selectedCount={selectedCount}
                optionsHeader={optionsHeaderText}
                dataId={dataIds?.selectOptionsRoot}
                size={inputSize}
                onMouseOver={handleOptionsMouseOver}
                onBlur={handleOptionsBlur}
                showClearAllButton={showClearAllButton}
                clearAllButtonLabel={clearAllButtonLabel}
                onClickClearAll={onClickClearAll}
                {...selectOptionsProps}
            >
                {options?.map((option, index) => (
                    <SelectOption
                        value={option.value}
                        key={option.value}
                        dataId={dataIds?.selectOption(index)}
                        size={inputSize}
                        icon={multiselect && <Checkbox checked={isOptionChecked(option.value)} onChange={_.noop} disableExtraActionArea />}
                        disableIconContainer
                        {...option.optionProps}
                        {...getSelectOptionHandlers(option.value)}
                    >
                        {option.label}
                    </SelectOption>
                ))}
            </SelectOptions>
        </ReactMenu.Wrapper>
    );
};
