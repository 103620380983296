import React from 'react';
import { TableCellProps } from './TableCellTypes';
import { useClasses } from './hooks/useClasses';
import { Typography } from '../../../Typography';

import './TableCell.scss';

export const TableCellView: React.FC<TableCellProps> = React.memo((props) => {
    const { className, children, hideOverflow = false, actionsColumn, justify = 'start', ...tableCellProps } = props;
    const classes = useClasses({ className, hideOverflow, actionsColumn, justify });
    return (
        <td className={classes.root} {...tableCellProps}>
            <div className={classes.content.root}>
                {typeof children === 'string' ? (
                    <Typography variant="body-md" element="div" hideOverflow={hideOverflow}>
                        {children}
                    </Typography>
                ) : (
                    children
                )}
            </div>
        </td>
    );
});
