import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createDataId } from '../../../../common/utils/dataId';
import { TransactionRowDTO } from '../../../../services/types/ApiTypes';
import { MainPage, MainPageType } from '../../../../components/MainPage/MainPage';
import { ContentBlock } from '../../../../components/ContentBlock/ContentBlock';
import { ContentBlockHeader, ContentBlockHeaderType } from '../../../../components/ContentBlock/ContentBlockHeader';
import { Typography } from '../../../../components/Ui/Typography';
import { ContentBlockBody } from '../../../../components/ContentBlock/ContentBlockBody';
import { DataGrid } from '../../../../components/Ui/DataGrid/DataGrid';
import { createColumns, createRowObject, dataId, getTransactionsRowDimensions, rootClassName } from './TransactionRowsHelper';
import { DataGridColumn } from '../../../../components/Ui/DataGrid/DataGrid/components/DataGridColumn';
import { Checkbox } from '../../../../components/Ui/Inputs/Checkbox';
import { TableRowActions } from '../../../../components/Ui/DataGrid/layout/TableRowActions';
import { ContentBlockFooter, ContentBlockFooterType } from '../../../../components/ContentBlock/ContentBlockFooter';
import { DisplayData } from '../../../../components/Ui/DisplayData';
import { trimAfterComma } from '../invoice-rows/InvoiceRowsHelper';
import { Button } from '../../../../components/Ui/Button';
import Icon, { ICONS } from '../../../../components/Icon/Icon';
import { ExpandedRowContent } from './components/ExpandedRowContent/ExpandedRowContent';
import { TransactionRowsProps } from './TransactionRowsTypes';
import { formatMoneyToShowSeparators } from '../invoice-header/utils';
import { isSystemSetting } from '../../../../common/user/userPermissionUtil';
import useIsMobile from '../../../../common/hooks/useIsMobile';

import './TransactionRows.scss';

const TransactionRows: React.FC<TransactionRowsProps> = ({ invoice, getTransactionRowsList, updateCustomCostObjectiveArray, transactionRowsLoadable, customCostObjectives }) => {
    const [transactionRowsList, setTransactionRowsList] = React.useState<TransactionRowDTO[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [page, setPage] = React.useState<number>(1);
    const isMobile = useIsMobile();

    const { t } = useTranslation();

    const isProducItemModuleActive = useMemo(() => isSystemSetting('IsProductItemsModulActive'), []);

    const defaultColumns = useMemo(() => createColumns(isMobile, t), [isMobile]);

    const tableItems = useMemo((): (TransactionRowDTO | React.ReactNode)[] => {
        const result = [];

        for (const row of transactionRowsList) {
            result.push(createRowObject(row));
        }
        setIsLoading(false);
        return result;
    }, [transactionRowsList, isMobile]);

    const renderCheckBox = useCallback(() => {
        return (
            <TableRowActions>
                <Checkbox />
            </TableRowActions>
        );
    }, []);

    const renderExpandedRowContent = useCallback(
        (index: number) => {
            return (
                <ExpandedRowContent
                    dataId={createDataId(dataId, 'expanded-row', transactionRowsList[index].Id)}
                    invoiceStatus={invoice.Status}
                    data={transactionRowsList[index]}
                    isMobile={isMobile}
                    isProducItemModuleActive={isProducItemModuleActive}
                />
            );
        },
        [tableItems],
    );

    const renderRowCellContent = useCallback(
        (row: TransactionRowDTO) => {
            return (
                <div className={`${rootClassName}__cell`}>
                    <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'description')} className={`${rootClassName}__cell-desc`} variant="body-md" element="div">
                        {row.Description}
                    </Typography>
                    <div data-id={createDataId(dataId, row.OrderNo, 'cell')} className={`${rootClassName}__cell-sums`}>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'net')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.SumWithoutVat}
                        </Typography>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'vat')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.VAT}
                        </Typography>
                        <Typography dataId={createDataId(dataId, row.OrderNo, 'cell', 'total')} className={`${rootClassName}__cell-sum`} variant="body-md" element="span">
                            {row.Total}
                        </Typography>
                    </div>
                </div>
            );
        },
        [tableItems],
    );

    const renderActionsHeadContent = useCallback(() => {
        return (
            <TableRowActions>
                <Button iconButton variant="text" size="sm">
                    <Icon iconName={ICONS.EXPAND_ALL_24} />
                </Button>
            </TableRowActions>
        );
    }, []);

    const renderActionsCellContent = useCallback(() => {
        return (
            <TableRowActions>
                <Button iconButton variant="text" size="sm">
                    <Icon iconName={ICONS.EDIT_24} />
                </Button>
                <Button iconButton variant="text" size="sm">
                    <Icon iconName={ICONS.CHEVRON_UP_24} />
                </Button>
            </TableRowActions>
        );
    }, []);

    const getDefaultColumnProps = useCallback(
        (column) => {
            const props = {
                ...column,
            };
            if (isMobile) {
                props.width = 100;
                props.renderCellContent = renderRowCellContent;
            }
            return props;
        },
        [isMobile],
    );

    const handleLoadMore = () => {
        const totalPage = Math.ceil(transactionRowsLoadable.payload?.TotalCount / transactionRowsLoadable.payload?.Take);
        if (isLoading || totalPage === page) {
            return;
        }
        const nextPage = page + 1;
        setIsLoading(true);
        getTransactionRowsList(invoice.Id, nextPage);
        setPage(nextPage);
    };

    useEffect(() => {
        if (transactionRowsLoadable?.payload?.Items && customCostObjectives?.length > 0 && invoice?.Id) {
            setTransactionRowsList(getTransactionsRowDimensions(transactionRowsLoadable.payload.Items, customCostObjectives));
        }
    }, [transactionRowsLoadable?.payload, customCostObjectives]);

    useEffect(() => {
        if (invoice?.AccountingDate) {
            updateCustomCostObjectiveArray(invoice?.AccountingDate.toString());
        }
    }, [invoice?.AccountingDate]);

    useEffect(() => {
        if (invoice?.Id) {
            setPage(1);
            setTransactionRowsList([]);
            getTransactionRowsList(invoice.Id, 1);
        }
    }, [invoice]);

    //TODO: Remove when invoice details will be on React
    if (!transactionRowsList.length) {
        return null;
    }

    const title = `${t('component.transactionRows.heading')} (${transactionRowsLoadable.payload?.TotalCount || 0})`;
    const rowsNet = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsPrice.toString(), 4, 2));
    const rowsVat = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsVAT.toString(), 4, 2));
    const rowsTotal = formatMoneyToShowSeparators(trimAfterComma(invoice?.TransactionRowsTotal.toString(), 4, 2));

    return (
        <MainPage className={rootClassName} type={MainPageType.WIDE}>
            <ContentBlock>
                <ContentBlockHeader
                    className={`${rootClassName}__header`}
                    dataId={createDataId(dataId, 'header')}
                    type={ContentBlockHeaderType.TABLE_HEADER}
                    title={
                        <Typography variant="h1" element="span">
                            {title}
                        </Typography>
                    }
                />
                <ContentBlockBody className={`${rootClassName}__table-content`} dataId={createDataId(dataId, 'contentBlockBody')}>
                    <div className={`${rootClassName}__table`}>
                        <DataGrid items={tableItems} renderExpandedRowContent={renderExpandedRowContent} isLoading={isLoading} height={1000} onLoadMore={handleLoadMore} isWidthFixed>
                            <DataGridColumn
                                key={invoice.Id}
                                renderCellContent={renderCheckBox}
                                actionsColumn
                                columnName="bulk-button"
                                cellProps={{ justify: 'center' }}
                                headCellProps={{ justify: 'center' }}
                                renderHeadCellContent={renderCheckBox}
                            />
                            {defaultColumns.map((column) => (
                                <DataGridColumn key={column.columnName} {...getDefaultColumnProps(column)} />
                            ))}

                            <DataGridColumn
                                actionsColumn
                                columnName="action-buttons"
                                cellProps={{ justify: 'end' }}
                                headCellProps={{ justify: 'end' }}
                                renderHeadCellContent={renderActionsHeadContent}
                                renderCellContent={renderActionsCellContent}
                            />
                        </DataGrid>
                    </div>
                </ContentBlockBody>
                <ContentBlockFooter contentClass={`${rootClassName}__footer`} type={ContentBlockFooterType.PAGER}>
                    <Typography className={`${rootClassName}__footer-info`} dataId={createDataId('footer', 'info')} variant="body-md" element="div">
                        {t('component.transactionRows.footer.info')}
                    </Typography>
                    <div className={`${rootClassName}__footer-sums`} data-id={createDataId(dataId, 'footer', 'sums')}>
                        <DisplayData dataId={createDataId(dataId, 'footer', 'sum', 'sum')} label={t('component.transactionRows.footer.sum')} value={rowsNet} justifyContent="end" size="sm" />
                        <DisplayData dataId={createDataId(dataId, 'footer', 'sum', 'vat')} label={t('component.transactionRows.footer.vat')} value={rowsVat} justifyContent="end" size="sm" />
                        <DisplayData
                            dataId={createDataId(dataId, 'footer', 'sum', 'total')}
                            label={t('component.transactionRows.footer.grandTotal')}
                            value={rowsTotal}
                            justifyContent="end"
                            size="sm"
                        />
                    </div>
                </ContentBlockFooter>
            </ContentBlock>
        </MainPage>
    );
};

export default TransactionRows;
