import React from 'react';

import { TablePreloaderRowProps } from './TablePreloaderRowTypes';
import { TableRow } from '../TableRow';
import { TableCell } from '../TableCell';
import { useClasses } from './hooks/useClasses';
import { CircularProgress } from '../../../../CircularProgress';
import { Typography } from '../../../Typography';

import './TablePreloaderRow.scss';

export const TablePreloaderRowView = React.forwardRef<HTMLTableRowElement, TablePreloaderRowProps>(function TablePreloaderView(props, ref) {
    const { className, colSpan, minWidth, left, text } = props;
    const classes = useClasses({ className });

    return (
        <TableRow className={classes.root} ref={ref}>
            <TableCell colSpan={colSpan} style={{ minWidth, left }}>
                <div className={classes.cellContent}>
                    <CircularProgress />
                    <Typography variant="label-lg">{text}</Typography>
                </div>
            </TableCell>
        </TableRow>
    );
});
