import classNames from 'classnames';
import { TableRowProps } from '../TableRowTypes';

const rootClassName = 'ui-table-row';

type Params = Pick<TableRowProps, 'className' | 'theadRow' | 'isActive' | 'isHovered' | 'isExpandableRow'>;

export const useClasses = (props: Params) => {
    const { className, theadRow, isActive, isHovered, isExpandableRow } = props;
    return {
        root: classNames(
            rootClassName,
            {
                [`${rootClassName}--thead`]: theadRow,
                [`${rootClassName}--is-active`]: isActive,
                [`${rootClassName}--is-hovered`]: isHovered,
                [`${rootClassName}--is-expanded-row`]: isExpandableRow,
            },
            className,
        ),
    };
};
