export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }

    return {
        root: dataId,
        input: `${dataId}-input`,
        checkmarkContainer: `${dataId}-checkmark-container`,
    };
};
