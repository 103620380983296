import React, { useMemo } from 'react';
import { Menu } from 'react-aria-menubutton';

import { SelectOptionsProps } from './SelectOptionsTypes';
import { useClasses } from './hooks/useClasses';
import Scrollbars from '../../../../../Scrollbars/Scrollbars';
import { Typography, TypographyProps } from '../../../../Typography';
import { useDataId } from './hooks/useDataId';
import { Button } from '../../../../Button';

import './SelectOptions.scss';

export const SelectOptionsView: React.FC<React.PropsWithChildren<SelectOptionsProps>> = (props) => {
    const { children, value, optionsHeader, selectedCount, actions, dataId, className, size = 'md', clearAllButtonLabel, showClearAllButton, onClickClearAll, ...menuProps } = props;
    const isShowDivider = Boolean(actions) || optionsHeader || typeof selectedCount === 'number';
    const dataIds = useDataId(dataId);
    const labelCounterSize: TypographyProps['variant'] = size === 'md' ? 'label-sm' : 'label-xs';
    const isClearAllButtonVisible = Boolean(showClearAllButton && clearAllButtonLabel);
    const classes = useClasses({ className, size, isClearAllButtonVisible });

    const selectedItems = useMemo(() => {
        return React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
                return null;
            }
            const el = child as React.ReactElement<SelectOptionsProps>;
            return el?.props?.value === value && el.props.value;
        }).filter(Boolean);
    }, [value]);

    return (
        <Menu className={classes.root} value={value} data-id={dataIds?.root} {...menuProps}>
            <Scrollbars hideTracksWhenNotNeeded autoHeightMax="320px" className={classes.dropdownMenu.scrollbar}>
                {actions && (
                    <div className={classes.dropdownMenu.actions} data-id={dataIds?.actions}>
                        {actions}
                    </div>
                )}

                {isShowDivider && (
                    <div className={classes.dropdownMenu.divider.root} data-id={dataIds?.dividerRoot}>
                        {(optionsHeader || selectedCount) && (
                            <div className={classes.dropdownMenu.divider.header.root}>
                                {optionsHeader && (
                                    <Typography variant={labelCounterSize} className={classes.dropdownMenu.divider.header.label} dataId={dataIds?.optionsHeader}>
                                        {optionsHeader}
                                    </Typography>
                                )}

                                {typeof selectedCount === 'number' && (
                                    <Typography variant={labelCounterSize} className={classes.dropdownMenu.divider.header.count} dataId={dataIds?.count}>
                                        ({selectedCount})
                                    </Typography>
                                )}
                            </div>
                        )}
                        <hr />
                    </div>
                )}
                <div className={classes.dropdownMenu.items} data-id={dataIds?.dropdownItems}>
                    {React.Children.map(children, (child) => {
                        const element = child as React.ReactElement<SelectOptionsProps>;

                        if (!React.isValidElement(element)) {
                            return null;
                        }

                        if (selectedItems.includes(element.props.value)) {
                            return React.cloneElement(child as React.ReactElement<SelectOptionsProps>, { selected: true });
                        }

                        return child;
                    })}
                </div>
                {/* Not in design */}
                {isClearAllButtonVisible && (
                    <Button variant="text" className={classes.dropdownMenu.clearAllButton} fullWidth onClick={onClickClearAll}>
                        {clearAllButtonLabel}
                    </Button>
                )}
            </Scrollbars>
        </Menu>
    );
};
