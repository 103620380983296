import React, { forwardRef } from 'react';
import { InputHelperTextProps } from './InputHelperTextTypes';
import { Typography } from '../../../Typography';
import { useClasses } from './hooks/useClasses';
import './InputHelperText.scss';
import { useDataId } from './hooks/useDataId';

export const InputHelperTextView = forwardRef(function InputHelperText(props: InputHelperTextProps, ref: React.Ref<HTMLDivElement>) {
    const { children, className, error, startIcon, disabled, dataId, ...rootProps } = props;
    const classes = useClasses({ className, error, disabled });
    const dataIds = useDataId(dataId);
    return (
        <div {...rootProps} className={classes.root} ref={ref} data-id={dataIds?.root}>
            {startIcon && (
                <div className={classes.startIcon} data-id={dataIds?.startIcon}>
                    {startIcon}
                </div>
            )}
            <Typography variant="label-md" className={classes.content} dataId={dataIds?.textContent}>
                {children}
            </Typography>
        </div>
    );
});
