import classNames from 'classnames';
import { InputHelperTextProps } from '../InputHelperTextTypes';

type Params = Pick<InputHelperTextProps, 'className' | 'error' | 'disabled'>;

const rootClassName = 'ui-input-helper-text';

export const useClasses = (params: Params) => {
    const { className, error, disabled } = params;

    return {
        root: classNames(rootClassName, { [`${rootClassName}--disabled`]: disabled }, className),
        content: classNames(`${rootClassName}__content`, { [`${rootClassName}__content--error`]: error }),
        startIcon: classNames(`${rootClassName}__start-icon`),
    };
};
