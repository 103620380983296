import React from 'react';
import { TableRowActionsProps } from './TableRowActionsTypes';
import { useClasses } from './hooks/useClasses';
import './TableRowActions.scss';

export const TableRowActionsView: React.FC<TableRowActionsProps> = (props) => {
    const { className, children } = props;
    const classes = useClasses({ className });
    return <div className={classes.root}>{children}</div>;
};
