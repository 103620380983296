import classNames from 'classnames';
import { DataGridHeadProps } from '../DataGridHeadTypes';

const rootClassName = 'ui-data-table-header';

export const useClasses = (props: DataGridHeadProps) => {
    const { className } = props;
    return {
        root: classNames(rootClassName, className),
        row: `${rootClassName}__row`,
    };
};
