import { createDataId } from '../../../../../../common/utils/dataId';

export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }
    return {
        root: dataId,
        labelText: createDataId(dataId, 'label'),
        tooltip: createDataId(dataId, 'tooltip'),
    };
};
