import classNames from 'classnames';
import { TextInputProps } from '../TextInputTypes';

const rootClassName = 'ui-text-input';

type Params = Pick<TextInputProps, 'className' | 'inputSize'>;

export const useClasses = (params: Params) => {
    const { className, inputSize } = params;

    return {
        root: classNames(rootClassName, `${rootClassName}--size-${inputSize}`, className),
        error: {
            content: `${rootClassName}__error__content`,
        },
    };
};
