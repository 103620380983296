import { TooltipTriggerProps } from '../TooltipTriggerTypes';

export const uiTooltipRootClassName = 'ui-tooltip-trigger';

export const useClasses = (props: Pick<TooltipTriggerProps, 'size'>) => {
    const { size } = props;
    return {
        root: uiTooltipRootClassName,
        size: `${uiTooltipRootClassName}--size-${size}`,
        interactionZone: {
            root: `${uiTooltipRootClassName}__interaction-area`,
            size: `${uiTooltipRootClassName}__interaction-area--size-${size}`,
        },
        icon: {
            root: `${uiTooltipRootClassName}__icon`,
            size: `${uiTooltipRootClassName}__icon--size-${size}`,
        },
    };
};
