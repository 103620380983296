import React from 'react';

import { TableProps } from './TableTypes';
import { useClasses } from './hooks/useClasses';

import './Table.scss';

export const TableView: React.FC<TableProps> = (props) => {
    const { children, className, tableRef, tableProps, rootProps, rootRef } = props;
    const classes = useClasses({ className });
    return (
        <div className={classes.root} {...rootProps} ref={rootRef}>
            <table className={classes.table.root} ref={tableRef} {...tableProps}>
                {children}
            </table>
        </div>
    );
};
