import { IconSize } from '../Icon/Icon';
import { CircularProgressProps } from './CircularProgressTypes';

export const iconSizesMap: Map<CircularProgressProps['size'], IconSize> = new Map([['sm', IconSize.SM]]);

export const rootClassName = 'ui-circular-progress';
export const useClasses = (props: Pick<CircularProgressProps, 'size'>) => {
    const { size } = props;
    return {
        size: `${rootClassName}--size-${size}`,
    };
};
