import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';

import { TooltipTriggerProps } from './TooltipTriggerTypes';
import { useClasses, useDelayedCallback } from './hooks';

import { createDataIds } from './TooltipHelper';
import Icon24 from '../../Icon/icons/Info-24.svg';
import './TooltipTriggerView.scss';

export const TooltipTriggerView: React.ForwardRefExoticComponent<TooltipTriggerProps> = React.forwardRef<HTMLButtonElement, TooltipTriggerProps>(function InfoTooltipView(props, ref) {
    const { size = 'md', onClick, onHover, onLeave, timeout, dataId, className, ...rootProps } = props;
    const classes = useClasses({ size });

    const delayedCallback = useDelayedCallback(onHover, timeout);

    const handleMouseEnter = () => {
        delayedCallback.start();
    };

    const handleMouseLeave = () => {
        delayedCallback.stop();
        onLeave?.();
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        delayedCallback.stop();
        onClick?.(e);
    };
    const dataIds = createDataIds(dataId);

    return (
        <button
            className={classNames(classes.root, classes.size, className)}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onBlur={handleMouseLeave}
            onFocus={handleMouseEnter}
            data-id={dataIds?.root}
            ref={ref}
            {...rootProps}
        >
            <span className={classNames(classes.icon.root, classes.icon.size)} data-id={dataIds?.iconContainer}>
                <svg data-id={dataIds?.icon}>
                    <use xlinkHref={`#${Icon24.id}`} />
                </svg>
            </span>
            <span className={classNames(classes.interactionZone.root, classes.interactionZone.size)} />
        </button>
    );
});
