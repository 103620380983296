import { createDataId } from '../../../../../common/utils/dataId';

export const useDataId = (dataId?: string) => {
    if (!dataId) {
        return null;
    }
    return {
        root: dataId,
        input: createDataId(dataId, 'text-input'),
        selectOptionsRoot: createDataId(dataId, 'select-options'),
        selectOption: (index: number) => createDataId(dataId, `select-option-${index}`),
    };
};
