import classNames from 'classnames';
import { TableBodyProps } from '../TableBodyTypes';

const rootClassName = 'ui-table-body';

type Params = Pick<TableBodyProps, 'className'>;

export const useClasses = (props: Params) => {
    const { className } = props;
    return {
        root: classNames(rootClassName, className),
    };
};
