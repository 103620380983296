import classNames from 'classnames';
import { TableResizeTriggerProps } from '../TableResizeTriggerTypes';

const rootClassName = 'ui-table-resize-trigger';
type Params = Pick<TableResizeTriggerProps, 'className'>;
export const useClasses = (params: Params) => {
    const { className } = params;
    return {
        root: classNames(rootClassName, className),
        inner: `${rootClassName}__inner`,
    };
};
