import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash-es';

import { DataGridRowProps } from './DataGridRowTypes';
import { TableRow, TableCell } from '../../../layout';
import { useDataGridContext } from '../../../hooks/useDataGridContext';
import { useResizableContext } from '../../../contexts';
import { DataGridPreloader } from '../DataGridRowPreloader';

export const DataGridRowView: React.FC<DataGridRowProps> = React.memo(function DataGridRow(props) {
    const { index } = props;
    const { items, columns, renderExpandedRowContent, updateSizes, onClickRow, isLoading, loadingText } = useDataGridContext();
    const rowRef = useRef<HTMLTableRowElement>(null);
    const expandedRowRef = useRef<HTMLTableRowElement>(null);

    const item = useMemo(() => items[index], [items, index]);
    const expandedRow = useMemo(() => renderExpandedRowContent && renderExpandedRowContent(index), [index]);
    const { lastResizedOn } = useResizableContext();

    const handleClickRow = useCallback(() => {
        onClickRow?.(item);
    }, [item]);

    useEffect(() => {
        requestAnimationFrame(() => {
            if (rowRef.current) {
                const expandedRowHeight = expandedRowRef?.current?.clientHeight || 0;
                const newHeight = rowRef.current.clientHeight + expandedRowHeight;
                updateSizes(index, newHeight);
            }
        });
    }, [rowRef.current, lastResizedOn]);

    const renderLoadingRow = props.index >= items?.length && isLoading;
    if (renderLoadingRow && loadingText) {
        return <DataGridPreloader key={props.index} text={loadingText} ref={rowRef} />;
    }

    return (
        <>
            <TableRow key={index} ref={rowRef} onClick={handleClickRow}>
                {columns.map((column) => {
                    let cellContent = null;
                    if (column.field) {
                        cellContent = _.get(item, column.field, '');
                    }

                    if (typeof column.renderCellContent === 'function') {
                        cellContent = column.renderCellContent(item);
                    }

                    return (
                        <TableCell key={column.columnName} justify="end" {...column.cellProps}>
                            {cellContent}
                        </TableCell>
                    );
                })}
            </TableRow>
            {renderExpandedRowContent && (
                <TableRow ref={expandedRowRef} isExpandableRow>
                    <TableCell justify="start" colSpan={columns.length}>
                        {expandedRow}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
});
